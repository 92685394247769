<template>
  <sdModal
    centered
    type="primary"
    :title="i18n.t('accounts.trading.close_title')"
    :visible="data.visible"
    :onCancel="closeModal"
    :width="500"
    class="publish-modal"
  >
    <p>{{ i18n.t('accounts.trading.closing', { name: data.data?.name }) }}</p>
    <div class="modal-buttons df-jcsb-aic">
      <sdButton class="action__button" size="large" :disabled="isLoading" type="danger" @click="closeModal" raised>
        {{ i18n.t('start.cancel') }}
      </sdButton>
      <sdButton class="action__button" size="large" :disabled="isLoading" type="primary" @click="modalAction" raised>
        {{ i18n.t('accounts.trading.close') }}
      </sdButton>
    </div>
  </sdModal>
</template>

<script>
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {
          visible: false,
          data: null,
          type: null,
        };
      },
    },
  },

  setup(props, { emit }) {
    const { state, dispatch } = useStore();
    const i18n = reactive(useI18n());

    const isLoading = computed(() => state.publicAccounts.loading);

    const closeModal = () => {
      emit('update:data', { visible: false, data: props.data.data, type: props.data.type });
      // setTimeout(() => {
      //   emit('update:data', { visible: false, data: null });
      // }, 50);
    };

    const modalAction = async () => {
      await dispatch('closeDemoAccount', { id: props.data.data.id });
      closeModal();
      return;
    };

    return {
      i18n,
      isLoading,
      modalAction,
      closeModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-buttons {
  margin-top: 20px;
  gap: 10px;
  flex-wrap: nowrap;
  button {
    width: 50%;
  }
}
p {
  font-size: 16px;
  margin: 10px 0;
}
</style>
